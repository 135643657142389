import Vue from 'vue';
import Vuex from 'vuex';
import prizes from './prizes';
import winners from './winners';
import eventSalesReport from './event-sales-report-store';
import devices from './devices';
import modals from './modals';
import inSupportOfSalesReport from './in-support-sales-report';
import deviceSalesReport from './device-sales-report';
import ticketSalesReport from './ticket-sales-report';
import raffleSalesReport from './raffle-sales-report';
import topSellersReport from './top-sellers-report';
import editRaffle from './edit-raffle';
import orders from './orders';
import goldrushStationSalesReport from './goldrush-station-sales-report';
import organizations from './organizations';

import { Roles } from '@rafflebox-technologies-inc/auth-service-sdk';
import useMultiTabSessions from '@/lib/multi-tab-sessions';

import EventServiceV2 from '@/lib/event-service-v2';
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import WinnerServiceV2 from '@/lib/winner-service-v2';

Vue.use(Vuex);

const localStorage = window.localStorage;
const sessionStorage = window.sessionStorage;

useMultiTabSessions();

export default new Vuex.Store({
  state: {
    user: undefined,
    event: {},
    events: [],
    organization: {},
    disableCloseButton: true,
    isAccountOwner: false,
    isRaffleAdmin: false,
    isRbAdmin: false,
    noEnforceFocus: false,
    sessionToken: localStorage.getItem('token') || null,
    sessionUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    loggedOut: localStorage.getItem('loggedOut')
  },

  getters: {
    disableCloseButton: (state) => {
      return state.disableCloseButton;
    },
    getOrganization: (state) => {
      return state.organization;
    },
    getEvents: (state) => {
      return state.events;
    },
    getEvent: (state) => {
      return state.event;
    },
    getNoEnforceFocus: (state) => state.noEnforceFocus
  },

  mutations: {
    SET_USER(state, user) {
      localStorage.setItem('user', JSON.stringify(user));
      sessionStorage.setItem('user', JSON.stringify(user));
      state.sessionUser = user;
      state.user = user;
    },
    SET_USER_ROLES: (state) => {
      state.isAccountOwner = state.user.hasRole(Roles.ACCOUNT_OWNER);
      state.isRbAdmin = state.user.hasRole(Roles.RB_ADMIN);
      state.isRaffleAdmin = state.user.hasRole(Roles.RAFFLE_ADMIN);
    },
    SET_EVENT: (state, event) => {
      state.event = event;
    },
    SET_EVENTS_LIST: (state, events) => {
      state.events = events;
    },
    SET_ORGANIZATION: (state, organization) => {
      state.organization = organization;
    },
    SET_STRIPE_CONNECTED_ACCOUNT_ID: (state, stripeConnectedAccountId) => {
      state.organization.stripeConnectedAccountId = stripeConnectedAccountId;
    },
    SET_CLOSE_BUTTON: (state, payload) => {
      state.disableCloseButton = payload;
    },
    SET_NO_ENFORCE_FOCUS: (state, noEnforceFocus) => {
      state.noEnforceFocus = noEnforceFocus;
    },
    CLEAR_TOKEN(state) {
      localStorage.removeItem('token');
      sessionStorage.removeItem('token');
      state.sessionToken = null;
    },
    CLEAR_USER(state) {
      localStorage.removeItem('user');
      sessionStorage.removeItem('user');
      state.sessionUser = null;
    },
    SET_TOKEN(state, token) {
      localStorage.setItem('token', token);
      sessionStorage.setItem('token', token);
      state.sessionToken = token;
    },
    SET_LOGGED_OUT(state, loggedOut) {
      localStorage.setItem('loggedOut', loggedOut.toString());
      state.loggedOut = loggedOut;
    }
  },

  actions: {
    setUser({ commit }, user) {
      commit('SET_USER', user);
      if (user !== undefined) {
        commit('SET_USER_ROLES');
      }
    },
    setEvent({ commit }, event) {
      commit('SET_EVENT', event);
    },
    setEvents: async ({ commit }) => {
      const events = await EventServiceV2.listEvents();
      commit('SET_EVENTS_LIST', events.data);
    },
    setOrganization: async ({ commit }, id) => {
      const organization = await OrganizationServiceV2.retrieveOrganization(id);
      commit('SET_ORGANIZATION', organization);
    },
    setStripeConnectedAccountId: ({ commit }, stripeConnectedAccountId) => {
      commit('SET_STRIPE_CONNECTED_ACCOUNT_ID', stripeConnectedAccountId);
    },
    displayCloseButton: async ({ commit, state, rootGetters }) => {
      const prizeList = await rootGetters.getPrizeList;

      // SET_CLOSE_BUTTON is poorly named but it should be SET_CLOSE_BUTTON_DISABLED
      // If raffle is not over, disable button
      if (state.event.status !== 'ended') return commit('SET_CLOSE_BUTTON', true);

      // if no sales then event can be closed no matter the rest of the criteria
      if (state.event.actualJackpotCents === 0) return commit('SET_CLOSE_BUTTON', false);

      const grandPrizeWinner = await WinnerServiceV2.listWinners(state.event.id, {
        page: 0,
        pageSize: 1,
        status: 'confirmed',
        category: 'jackpot'
      });

      // if grand prize winner not confirmed disable button
      if (grandPrizeWinner.pagination.total !== 1) return commit('SET_CLOSE_BUTTON', true);

      // if there exists a prize that does not have the correct amount of winners disable button

      for (const prize of prizeList) {
        const winners = await WinnerServiceV2.listWinners(state.event.id, {
          prizeId: prize.id,
          page: 0,
          pageSize: 1,
          status: 'confirmed'
        });

        if (winners.pagination.total !== prize.quantity) {
          return commit('SET_CLOSE_BUTTON', true);
        }
      }

      // If ended, grand prize confirmed, all prizes picked, enable button
      return commit('SET_CLOSE_BUTTON', false);
    },
    setNoEnforceFocus: async ({ commit }, noEnforceFocus) => {
      commit('SET_NO_ENFORCE_FOCUS', noEnforceFocus);
    },
    setToken({ commit }, token) {
      commit('SET_TOKEN', token);
    },
    setLoggedOut({ commit }) {
      commit('CLEAR_TOKEN');
      commit('CLEAR_USER');
      commit('SET_LOGGED_OUT', true);
    },
    setLoggedIn({ commit, dispatch }, { token, user }) {
      commit('SET_TOKEN', token);
      commit('SET_USER', user);
      commit('SET_LOGGED_OUT', false);
      dispatch('clearAllOrganizations');
    }
  },

  modules: {
    prizes,
    winners,
    eventSalesReport,
    devices,
    modals,
    inSupportOfSalesReport,
    deviceSalesReport,
    ticketSalesReport,
    raffleSalesReport,
    goldrushStationSalesReport,
    topSellersReport,
    editRaffle,
    orders,
    organizations
  }
});
