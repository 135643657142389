<template>
  <b-navbar class="navbar--top" toggleable="lg" type="dark" variant="dark" fixed sticky>
    <b-navbar-brand> {{ user.organizationName }} </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" class="nav-collapse" is-nav>
      <b-navbar-nav class="org-select ml-auto">
        <ChangeOrganizationSelect
          @error="changeOrganizationError"
          @success="changeOrganizationSuccess"
          class="change-organization-select"
          v-if="canChangeOrganization && showChangeOrganizationSelect"
          showLabel="false"
          placeholderText="Change Organization"
        />
        <b-modal id="error-modal" title="Error" hide-footer centered>
          <Alert variant="red" icon="exclamation">{{ errorMessage }}</Alert>
        </b-modal>
      </b-navbar-nav>

      <b-navbar-nav class="mobile-menu">
        <b-nav-item to="/" v-if="canViewDashboard">Dashboard</b-nav-item>
        <b-nav-item to="/admin" v-if="showAdmin">Admin</b-nav-item>
        <b-nav-item to="/raffles" v-if="showRaffles">Raffles</b-nav-item>
        <b-nav-item to="/goldrush" v-if="canViewGoldrushRaffles">Goldrush</b-nav-item>
        <b-nav-item to="/orders/v2" v-if="canViewOrdersV2">Orders V2</b-nav-item>
        <b-nav-item to="/orders" v-else-if="canViewOrders">Orders</b-nav-item>
        <b-nav-item to="/customers" v-if="canViewCustomers">Customers</b-nav-item>
        <b-nav-item to="/stations" v-if="canViewStations">Stations</b-nav-item>
        <b-nav-item to="/devices" v-if="canViewDevices">Devices</b-nav-item>
        <b-nav-item to="/reports" v-if="showReports">Reports</b-nav-item>
        <b-nav-item to="/message-center" v-if="showMessageCenter">Message Centre</b-nav-item>
        <b-nav-item to="/users" v-if="canViewUsers">Users</b-nav-item>
        <b-nav-item href="https://help.rafflebox.ca/" target="_blank">Knowledge Base</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="utility-nav">
        <b-nav-item-dropdown right>
          <template #button-content>
            <span class="fa-regular fa-user mr-2" />
            {{ user.name }}
          </template>
          <b-dropdown-item href="/account" v-if="canViewAccount">
            <div class="fa-solid fa-gear mr-2 pt-2"></div>
            Account
          </b-dropdown-item>
          <b-dropdown-item href="/profile">
            <div class="fa-solid fa-user mr-2 pt-2"></div>
            Profile
          </b-dropdown-item>
          <b-dropdown-item href="/system-info" v-if="showSystemInfo">
            <div class="fa-solid fa-circle-question mr-2 pt-2"></div>
            System Info
          </b-dropdown-item>
          <b-dropdown-item href="/logout">
            <div class="fa-solid fa-right-from-bracket mr-2 pt-2"></div>
            Log Out
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';

import ChangeOrganizationSelect from '@/components/forms/ChangeOrganizationSelect';
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
import Alert from '@/components/ui/Alert';

export default {
  components: {
    Alert,
    ChangeOrganizationSelect
  },
  data() {
    return {
      showChangeOrganizationSelect: false,
      unsubscribe: null,
      user: {
        name: null,
        organizationName: null
      },
      errorMessage: null,
      viewReports: false,
      viewAdmin: false,
      viewRaffles: false,
      viewMessageCenter: false,
      viewSystemInfo: false,
      canChangeOrganization: false,
      canViewCustomers: false,
      canViewDevices: false,
      canViewOrders: false,
      canViewOrdersV2: false,
      canViewGoldrushRaffles: false,
      canViewAccount: false,
      canViewUsers: false,
      canViewDashboard: false,
      canViewStations: false
    };
  },
  computed: {
    showReports() {
      return this.viewReports;
    },
    showAdmin() {
      return this.viewAdmin;
    },
    showRaffles() {
      return this.viewRaffles;
    },
    showMessageCenter() {
      return this.viewMessageCenter;
    },
    showSystemInfo() {
      return this.viewSystemInfo;
    }
  },
  methods: {
    changeOrganizationSuccess() {
      this.$router.go(0);
    },
    changeOrganizationError(error) {
      this.errorMessage = this.parseError(error).message;
      this.$bvModal.show('error-modal');
    }
  },
  async mounted() {
    this.viewReports = await unleashFeatureEnabled(UnleashKeys.ViewReports);
    this.viewAdmin = await unleashFeatureEnabled(UnleashKeys.Admin);
    this.viewRaffles = await unleashFeatureEnabled(UnleashKeys.ViewRaffles);
    this.viewMessageCenter = await unleashFeatureEnabled(UnleashKeys.MessageCenter);
    this.viewSystemInfo = await unleashFeatureEnabled(UnleashKeys.ViewSystemInfo);
    this.canChangeOrganization = await unleashFeatureEnabled(UnleashKeys.ChangeOrganization);
    this.canViewCustomers = await unleashFeatureEnabled(UnleashKeys.ViewCustomers);
    this.canViewDevices = await unleashFeatureEnabled(UnleashKeys.ViewDevices);
    this.canViewOrders = await unleashFeatureEnabled(UnleashKeys.ViewOrders);
    this.canViewOrdersV2 = await unleashFeatureEnabled(UnleashKeys.OrdersV2Beta);
    this.canViewGoldrushRaffles = await unleashFeatureEnabled(UnleashKeys.ViewGoldrushRaffles);
    this.canViewAccount = await unleashFeatureEnabled(UnleashKeys.ViewAccount);
    this.canViewUsers = await unleashFeatureEnabled(UnleashKeys.ViewUsers);
    this.canViewDashboard = await unleashFeatureEnabled(UnleashKeys.ViewDashboard);
    this.canViewStations = await unleashFeatureEnabled(UnleashKeys.ViewStations);
  },
  created: async function () {
    const sessionUser = await getAuth().sessionUser();

    if (sessionUser) {
      // Render of `<template />` is sync. Therefore we save the role in the component’s data, then render that data.
      this.user.name = sessionUser.name;
      this.user.organizationName = sessionUser.organizationName;

      if (sessionUser.hasRole(Roles.RB_ADMIN)) {
        this.showChangeOrganizationSelect = true;
      } else {
        try {
          const result = await OrganizationServiceV2.listOrganizations();
          if (result.data.length > 0) {
            this.showChangeOrganizationSelect = true;
          }
        } catch (error) {
          this.errorMessage = this.parseError(error).message;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar--top {
  min-height: 80px;
  margin-left: 99px;
  padding: 1.125rem 1rem;
  border-bottom: 1px solid #343a40;

  .mobile-menu {
    display: none;
  }

  @media screen and (max-width: 62em) {
    margin-left: 0;

    .mobile-menu {
      display: flex;
      align-items: flex-end;
    }
  }
}

.navbar--top .navbar-brand {
  font-family: 'futura-pt', 'Roboto', sans-serif;
  font-weight: 600;
  margin-left: 0.875rem;
}

.navbar .mobile-menu .nav-link {
  text-align: right;
}

.navbar-organization {
  padding-left: 1rem;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.nav-collapse {
  @media screen and (max-width: 62em) {
    padding: 2rem 1rem 0;
    font-size: 1.125rem;
  }
}

.org-select {
  max-width: 55rem;
  width: 100%;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 62em) {
    align-items: flex-end;
    margin-left: 0;
  }
}
</style>

<style lang="scss">
.org-select div label {
  color: white;
  margin-right: 15px;
  margin-bottom: 0;
}

.change-organization-select {
  width: 25rem;

  @media screen and (max-width: 62em) {
    width: 100%;
  }
}

.mobile-menu .nav-link.router-link-exact-active {
  color: #ffffff;
}

.utility-nav {
  @media screen and (max-width: 62em) {
    text-align: right;

    ul.show {
      display: inline-block;
    }
  }
}
</style>
