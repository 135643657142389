<template>
  <div v-if="!busy" id="app">
    <component class="page-fade-in" :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import store from '@/store';

export default {
  metaInfo: {
    title: 'Rafflebox Dashboard'
  },
  data() {
    return {
      busy: true
    };
  },
  async created() {
    const sessionUser = await getAuth().sessionUser();

    if (sessionUser) {
      await store.dispatch('setOrganization', sessionUser.organizationUuid);
      await store.dispatch('setEvents');
    }

    this.busy = false;
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'blank';
    }
  }
};
</script>
<style>
@import url('https://use.typekit.net/tlb6hrw.css');

html,
body {
  font-family: 'Roboto', sans-serif;
}

.container {
  text-align: left;
  margin-top: 1.875rem;
  margin-left: 7rem;
}

@media screen and (max-width: 62em) {
  .container {
    margin-left: auto;
  }
}

.table-overflow {
  display: block;
  max-width: 100%;
  margin-bottom: 2rem;
  overflow-x: auto;
}

#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

h1 {
  font-family: 'futura-pt', 'Roboto', sans-serif;
  padding-bottom: 1rem;
  font-size: 2rem;
}

h2 {
  padding-bottom: 1rem;
  font-size: 1.5rem;
}

.page-fade-in {
  animation: fadeIn 0.1s ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
