import { Roles } from '@rafflebox-technologies-inc/auth-service-sdk';
import store from '@/store';
import { isEnvironment } from './environments.js';

const environmentSpecificFeatures = {};

const roleSpecificFeatures = {
  Admin: [Roles.RB_ADMIN, Roles.RB_FINANCE],
  Invoices: [Roles.RB_FINANCE]
  // test
};

export const checkEnv = (name) => {
  // environment checks
  if (environmentSpecificFeatures[name]) {
    return isEnvironment(environmentSpecificFeatures[name]);
  }
};

const checkRoles = (sessionUser, name) => {
  const requiredRoles = roleSpecificFeatures[name];

  return sessionUser.hasRole(requiredRoles);
};

const enableFeature = (name, orgName = undefined) => {
  const sessionUser = store.state.user;

  // God Mode!
  if (sessionUser && sessionUser.hasRole(Roles.RB_ADMIN)) {
    return true;
  }

  const envCheckResult = checkEnv(name);
  const roleCheckResult = sessionUser ? checkRoles(sessionUser, name) : undefined;

  // Simple org check
  if (orgName) {
    if (envCheckResult && roleCheckResult && sessionUser.organizationName === orgName) {
      return true;
    } else if (envCheckResult === undefined && roleCheckResult && sessionUser.organizationName === orgName) {
      return true;
    }
  }

  if (envCheckResult && roleCheckResult) {
    return true;
  } else if (envCheckResult === undefined && roleCheckResult) {
    return true;
  }

  return false;
};

export default enableFeature;
